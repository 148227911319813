<template>
  <div>
    <contentHeader :title="title" @add="createNew" />

    <!-- add dialog -->
    <q-dialog v-model="createDialog">
      <q-card style="width: 100%">
        <q-bar>
          <span class="form-title">Create a tag</span>
          <q-space />
          <q-icon v-close-popup name="far fa-times" />
        </q-bar>

        <q-card-section>
          <q-form class="q-pa-md">
            <q-input
              filled
              v-model="tagname"
              label="Tag"
              hint="Enter a unique and descriptive term"
              lazy-rules
              :rules="[
                (val) => (val && val.length > 0) || 'Please type something',
              ]"
            />
          </q-form>
        </q-card-section>

        <q-card-actions align="center">
          <q-btn label="Cancel" v-close-popup color="secondary" />
          <q-btn label="Save" @click="save" color="primary" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showEdit" persisent full-width>
      <q-card>
        <q-bar class="bg-primary text-white"
          ><small>Edit Tag</small>
          <q-space />
          <q-btn v-close-popup flat dense round icon="fal fa-times" />
        </q-bar>
        <q-card-section>
          <q-input
            filled
            v-model="tag.name"
            label="Tag"
            hint="Enter a unique and descriptive term"
          />
        </q-card-section>

        <q-card-section class="q-pa-md">
          <div class="row">
            <div class="col-4">
              <span class="text-h6">Iconic image</span>
              <q-uploader
                v-show="false"
                ref="uploadIconic"
                url="/api/upload"
                :headers="getHeaders(tag, 'tag-iconic')"
                :multiple="false"
                label="Iconic"
                @uploaded="processIconicUpload"
                auto-upload
                accept="image/*"
              />

              <q-img
                style="max-height: 31vh; max-width: 30vw"
                :src="getThumb()"
              >
                <div class="absolute-bottom text-subtitle1 text-center">
                  <q-btn
                    :disable="isEmpty(tag.iconic.url)"
                    round
                    icon="fa fa-trash"
                    @click="deleteIconic(tag.iconic.uuid)"
                  />
                  <q-btn
                    :disable="!isEmpty(tag.iconic.url)"
                    round
                    icon="fa fa-upload"
                    @click="$refs.uploadIconic.pickFiles()"
                  />
                </div>
              </q-img>
            </div>
            <div class="col-8">
              <span class="text-h6">About this tag</span>
              <q-editor v-model="tag.text" min-height="15vh" />
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="center">
          <q-btn no-caps v-close-popup color="secondary" label="Cancel" />
          <q-btn no-caps color="primary" @click="update(tag)" label="Ok" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <section class="q-pa-md">
      <!-- default-expand-all is supported in Quasar 2 :-( only -->
      <q-tree
        :nodes="treenodes"
        node-key="uuid"
        label-key="name"
        default-expand-all
      >
        <template v-slot:default-header="prop">
          <div
            class="row items-center drag-handle"
            draggable="true"
            data-drag-handle
            @dragstart="e => dragStart(e,prop.key)"
            @dragover.prevent="dragOver"
            @drop.prevent="e => drop_on(e,prop.key)"
          >
            <!--a href="#" @click="edit(prop.node)"-->
            <q-icon
              :name="prop.node.icon || 'fas fa-tag'"
              color="grey-7"
              size="14px"
              class="q-mr-sm"
            />

            <div class="text-weight-bold text-primary">
              {{ prop.node.name }}

              <q-btn
                icon="fad fa-trash"
                color="primary"
                flat
                round
                dense
                @click.stop="remove(prop.node.uuid)"
              />

              <q-btn
                icon="fad fa-pencil"
                color="primary"
                flat
                round
                dense
                @click.stop="edit(prop.node)"
              />

              <q-btn
                icon="fa fa-plus"
                color="primary"
                flat
                round
                dense
                @click.stop="add(prop.node.uuid)"
              />
            </div>
          </div>
        </template>
      </q-tree>

      <br />
    </section>

    <section>DEMO</section>

    <tag-options />
  </div>
</template>
<script>
import { API } from "../tic";
import { mapGetters } from "vuex";
import contentHeader from "@/admin/components/contentHeader";
import tagOptions from "@/components/hiTags";

import tag from '@/models/tag'

console.log("tag from models: ", tag)

export default {
  name: "admin-tags",
  props: ["showAdd", "title"],
  components: { contentHeader, tagOptions },
  mounted()  {
      this.load();
  },
  data () {
    return {
      createDialog: false,
      showEdit: false,
      mode: "Add",
      tag: {...tag.model},
      tagname: '',
      parent: "",
      treenodes: []
    }
  },

  computed: {
    ...mapGetters("user", { isAdmin: "isAdmin", user: "uuid" }),
  },

  watch: {
    showAdd() {
      this.add();
    }
  },

  methods: {
    dragStart(e,uuid) {
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/plain",uuid);
    },

    dragOver(e) {
      //e.dataTransfer.dropEffect = "move";
      //console.log('over',e);
    },

    drop_on(e,uuid) {
      const moved = event.dataTransfer.getData("text/plain");

      console.log('move node=',moved,'to target=',uuid);
    },

    getThumb() {
      return this.tag.iconic && this.tag.iconic.url
        ? this.tag.iconic.url
        : "/No-Image-Placeholder.svg";
    },

    deleteIconic(uuid) {
      API.post("/tags/delete-iconic", { tag: this.tag.uuid,  member: this.user }).then(
        (r) => {
          this.tag.iconic = { url: '', uuid: ''};
        }
      );
    },

    update(tag) {
      API.post("/tags/update", { tag: this.tag,  member: this.user }).then(
        (r) => {
          this.showEdit = false
        }
      );
    },

    edit(tag) {
      this.tag = tag
      this.showEdit = true
    },

    remove(tag) {
      this.$q
        .dialog({
          title: '<i class="fad fa-trash"></i>&nbsp;Confirm Delete',
          message: `Are you sure you want to delete this node?`,
          options: {
            type: "checkbox",
            model: [],
            items: [
             { label: 'Remove childs too', value: 'opt1', color: 'primary ' }
          ]
          },
          html: true,
          cancel: {
            noCaps: true,
            color: "grey-2",
            textColor: "black",
          },
          ok: { label: "Yes", color: "primary", noCaps: true },
        }).onOk(() => {
          API.post("/tags/delete", { tag: tag, member: this.user }).then(
            (r) => { this.load() }
          );
        });
    },


    load() {
      API.get("/tags").then(r => {
          this.treenodes = r.data.data
      })
    },


    createNew() {
      this.parent=''
      //this.tag =  {...tag.model}
      this.tagname=''
      console.log("model :", tag.model)

      this.createDialog = true

    },

    add(tag="") {
      console.log("add tag--")
      this.parent = tag
      this.createDialog = true
      this.tagname=''

    },


    save() {
        // add a tag in the tree
        const vm = this
        console.log("save tag: ", this.tag)
        API.post("/tags/add", {tag: this.tagname, member: this.user, parent: this.parent}).then(r=>{
            vm.createDialog =false
            vm.load()
        })
    },


    getHeaders(tag, context) {
      return [
        {
          name: "X-Session",
          value: this.$store.getters["user/session"],
        },
        { name: "X-Process", value: 'tag-iconic' },
        { name: "X-Tag", value: tag.uuid },
      ];
    },

    processIconicUpload(reply) {
      console.log("**** iconic new upload", reply);
      const thumb = JSON.parse(reply.xhr.response);
      console.log("t: ", thumb);
      this.tag.iconic = { ...thumb.data };
    },
  }
}

</script>


<style lang="stylus" scoped>
@import '~quasar-variables';

.drag-handle {
  float: left;
  // width: 50px;
  // height: 50px;
  cursor: grab;
  // background-color: $eigeel;
}
</style>
